.CharacteristicsStep {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.ChipsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.Chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.Chip {
    background-color: white !important;
    height: 40px !important;
    width: 120px !important;
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
}

.Chip:hover {
    transition: transform 0.3s ease, background-color 0.3s ease;
    transform: rotate(5deg);
}

.Chip.clicked {
    background: linear-gradient(159.59deg, #F7C8CF 37.77%, #EFAAF7 115.33%) !important;
}

.plus-icon {
    transition: transform 0.3s ease;
}

.plus-icon.rotated {
    transform: rotate(45deg);
}