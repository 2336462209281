.RelegionStep {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.CommonOptions {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.RelegionStepOption {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 180px;
    height: 180px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
}

.RelegionStepOption:hover {
    background-color: #EEEEF5;
}

.RelegionStepOption.clicked {
    background-color: #42455C;
    color: white;
    transition: background-color 0.3s ease-in-out;
}

.RelegionStepOption.clicked .MuiTypography-root{
    color: white;
}

.RelegionStepOptionWithGesture {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    position: relative;
}

.RelegionStepOptionGesture {
    background-color: #FFFFFF;
    max-width: 384px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px #00000026;
    border-radius: 8px;
    position: absolute;
    top: -40%;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: popUp 0.3s ease-in-out forwards;
}


@keyframes popUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}