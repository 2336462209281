.CandidatesScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    overflow-y: scroll;
}


.Title {
    position: relative;
}
