.App-background {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-image: url('../Background/background.svg');
  background-size: cover;
  overflow-y: hidden;
}

.App-header {
  padding-left: 115px;
  height: 100px;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.gradient-text {
  background: linear-gradient(90deg, #E0B9F4, #90B6FF, #F7C8CF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Main-button-gradient-text {
  background: linear-gradient(98.87deg, #BFBEFF 26.71%, #F7C8CF 90.25%);
  height: 52px;
  border-radius: 100px !important;
  padding: 10px 20px;
  text-transform: none !important;
  border: none;
  width: 156px;
  font-family: "proxima_nova_rgregular" !important;
  font-size: 16px !important;
}

.Main-button-gradient-text:hover {
  transition: transform 0.3s ease, background-color 0.3s ease;
  transform: scale(1.02);
}

.Main-button-gradient-text:disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
  border-radius: 100px !important;
  padding: 10px 20px;
  text-transform: none !important;
  border: none;
  width: 156px;
  font-family: "proxima_nova_rgregular" !important;
  font-size: 16px !important;
}

.Special-button-gradient-text {
  background: linear-gradient(270deg, #BFBEFF -9.49%, #90B6FF 77.01%);
  height: 52px;
  border-radius: 100px !important;
  padding: 10px 20px;
  text-transform: none !important;
  border: none;
  font-family: "proxima_nova_rgregular" !important;
  font-size: 16px !important;
}

.Special-button-gradient-text:disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.Footer {
  display: flex;
  flex-direction: column;
  background-color: #42475C;
  width: 100%;
}