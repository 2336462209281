@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
    src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
        url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
        url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
    src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
        url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
        url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adelle_rgregular';
    src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
    src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
        url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
        url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
        url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.proxima-nova-regular {
    font-family: "proxima_nova_rgregular", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
}