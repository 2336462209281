.TreatmentLocaleStep {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.SelectedCountriesPresenter {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
    background-color: #EAF1FD;
    border-radius: 8px;
    box-sizing: border-box;
    width: 400px;
}