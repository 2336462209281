.UserInformation {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
}

.UserInformationSubmission {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.CongratulationsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
}

.Input-gesture {
    background-color: #7DDBE1;
    width: 220px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px #00000026;
    border-radius: 8px;
    position: absolute;
    top: 110%;
    transform: scale(0);
    top: 0;
    right: -50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.5s;
    animation: popUp 0.3s ease-in-out forwards 0.5s;
}
