.EyeColors {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.EyeColor {
    cursor: pointer;
    display: flex;
    background-color: white;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 90px;
    width: 110px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
}

.EyeColor:hover {
    background-color: #EEEEF5;
}

.EyeColor.clicked {
    background-color: #42455C;
    transition: background-color 0.3s ease-in-out;
}
