.PhysicsStep {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.PhysicBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}