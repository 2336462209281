.Form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: hidden;
    flex-grow: 1;
}

.FormAnswers {
    position: relative;
    display: flex;
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 25px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 4px 32px 0px #0000001A;
    max-height: 100vh;
    overflow-y: scroll;
}

.FormController {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 100px;
    flex-grow: 1;
}

.Question {
    display: flex;
    height: 68%;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 15px;
    overflow-y: scroll;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.CustomizedSlider {
    display: flex;
    flex-direction: column;
    height: 12%;
    width: 100%;
    align-items: flex-end;
}

.ButtonsPanel {
    display: flex;
    height: 20%;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}

.QuestionTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.ButtonsPanel-back-next {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.blurred-background {
    filter: blur(5px);
    transition: filter 0.3s;
}