.SkinColors {
    display: flex;
    flex-direction: row;
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    border-right: 4px solid white;
    border-left: 4px solid white;

    /* White border with 8px thickness */
    border-radius: 8px;
    /* Rounded corners with 8px radius */
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
}

.SkinColor {
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    border-left: 4px solid white;
    border-right: 4px solid white;
}