.CandidatesGrid {
    display: grid;
    gap: 50px;
}

@media (min-width: 600px) {
    .CandidatesGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1200px) {
    .CandidatesGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.CandidateGridItemForHover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.CandidateGridItemForHover:hover {
    transform: scale(1.03);
}

.CandidateGridItem {
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    background-color: white;
    gap: 15px;
    width: 300px;
    justify-content: space-between;
    box-shadow: 0px 8px 24px 0px #95B7F750;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.CandidateGridItem:hover {
    background-color: #F5F5FD;
}

.ReadMoreButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: linear-gradient(98.87deg, #BFBEFF 26.71%, #F7C8CF 90.25%);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}