.rhap_container {
    background-color: #FCEDEF;
    border-radius: 10px;
    border: none !important;
    box-shadow: none;
}

.rhap_repeat-button {
    display: none;
}

.rhap_time {
    display: none !important;
}

.rhap_progress {
    background: linear-gradient(98.87deg, #BFBEFF 26.71%, #F7C8CF 90.25%);
    border-radius: 5px;
}

.rhap_volume-button,
.rhap_forward-button,
.rhap_rewind-button,
.rhap_play-pause-button {
    color: #42455C;
}

.rhap_progress-filled {
    background-color: #BFBEFF;
}