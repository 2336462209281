.GeneticTestStep {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.Explanation {
    display: flex;
    padding: 10px;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;
    width: 50%;
    background: #FFFFFF;
}

.Uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    height: 200px;
    width: 50%;
    border: 2px dashed #384EB74D;
    border-radius: 8px;
}

.Results {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
}

.GenResult {
    display: flex;
    flex-direction: column;
    height: 170px;
    width: 282px;
    padding: 15px;
    position: relative;
    gap: 10px;
    align-items: flex-start;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: popUp 0.3s ease-in-out forwards;
}

.GenResult-positivity {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
}

.GenResult-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@keyframes popUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}