.CandidateScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.CandidateCard {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 40px;
}

.CandidateInterview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px
}

.MatchingByCategory {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}

.Category {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 15px;
    box-sizing: border-box;
    width: 31%;
    box-shadow: 0px 8px 24px 0px #95B7F733;
    border-radius: 16px;
    gap: 15px;
    overflow-y: scroll;
}