.StartingDateStep {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.StartingDateStepOptionGesture {
    position: absolute;
    min-width: 120px;
    left: calc(100% + 25px);
    background-color: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    width: 270px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px #00000026;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.5s;
    animation: popUp 0.3s ease-in-out forwards 0.5s;
}

.StartingDateStepOption {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
    width: 384px;
    height: 52px;
    border-radius: 8px;
}

.StartingDateStepOption:hover {
    background-color: #EEEEF5;
}

.StartingDateStepOption.clicked {
    background-color: #BFBEFF;
    transition: background-color 0.3s ease-in-out;
}

@keyframes popUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}