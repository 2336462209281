.InitialScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 780px;
    height: 75%;
}

.BanksInformation {
    /* display: flex; */
    /* flex-direction: row; */
    /* width: 100%; */
    /* justify-content: space-between; */
    /* align-items: center; */
}