.ChatWindow {
    height: 450px;
    width: 700px;
    background-color: #EAF1FE;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MessagesContainer {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    gap: 20px;
}

.ChatMessage {
    width: 98%;
}

.ChatTextPlaceHolder {
    background-color: white;
    border-radius: 10px;
    max-width: 80%;
    min-width: 50%;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    transform: "scale(0.95)";
    animation: "popIn 0.3s ease-out forwards";
}

.MessageGenerator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 25px;
    box-sizing: border-box;
    height: 75px;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
}

@keyframes popIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}