.EthnicityStep {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 10px;
}

.EthnicityStepOption {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 16px 0px #D3D3FF80;
    width: 384px;
    height: 52px;
    border-radius: 8px;
}

.EthnicityStepOption:hover {
    background-color: #EEEEF5;
}


.EthnicityStepOption.clicked {
    background-color: #BFBEFF;
    transition: background-color 0.3s ease-in-out;
}